export default [
  {
    title: 'Dashboard',
    route: 'dashboard',
    icon: 'HomeIcon',
  },
  {
    title: 'Users',
    route: 'users',
    icon: 'UserIcon',
  },
  {
    title: 'Pending Users',
    route: 'pending-users',
    icon: 'UserIcon',
  },
  {
    title: 'Analytics',
    route: 'analytics',
    icon: 'ActivityIcon',
  },
  {
    title: 'Settings',
    route: 'settings',
    icon: 'SettingsIcon',
  },
  {
    title: 'Stripe',
    href: 'https://dashboard.stripe.com',
    icon: 'ExternalLinkIcon',
  },
]
